/* Simple file to include in component SCSS to get access to
 * variables and mixins */
// Pulled from Leaven and edited slightly

$border-radius: 5px;
$border-radius-lg: 8px;
$ease-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$popover-max-width: 500px;
$popover-font-size: 1rem;
$expanded-highlight-card-height: 398px;

// Bootstrap overrides

// lists
$list-group-item-padding-x: 0.5rem;
$list-group-item-padding-y: 0.4rem;

// headers
$h1-font-size: 2.2rem;
$h2-font-size: 1.8rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.35rem;
$h5-font-size: 1.2rem;
$h6-font-size: 1rem;

$white: #ffffff;
$grey-lightest: #f8fafc;
$grey-lighter: #f1f5f8;
$grey-light: #dae1e7;
$grey: #b8c2cc;
$grey-dark: #8795a1;
$grey-darker: #606f7b;
$grey-darkest: #3d4852;
$black: #22292f;

$red-darkest: #3b0d0c;
$red-darker: hsl(2, 80%, 33%);
$red-dark: #cc1f1a;
$red: #e3342f;
$red-light: #ef5753;
$red-lighter: #f9acaa;
$red-lightest: #fcebea;

$orange-dark: #ff6300;
$orange-darkest: desaturate(darken($orange-dark, 38%), 0%);
$orange-darker: desaturate(darken($orange-dark, 20%), 0%);
$orange: lighten($orange-dark, 6%);
$orange-light: lighten($orange-dark, 18%);
$orange-lighter: lighten($orange-dark, 34%);
$orange-lightest: lighten($orange-dark, 42%);
$orange-dark: darken($orange-dark, 2%);

$yellow: #ffde37;
$yellow-darkest: desaturate(darken($yellow, 48%), 0%);
$yellow-darker: desaturate(darken($yellow, 24%), 0%);
$yellow-dark: darken($yellow, 14%);
$yellow-light: lighten($yellow, 12%);
$yellow-lighter: lighten($yellow, 25%);
$yellow-lightest: lighten($yellow, 35%);

$green-darkest: #0f2f21;
$green-darker: #1a4731;
$green-dark: #1f9d55;
$green: #38c172;
$green-light: #51d88a;
$green-lighter: #a2f5bf;
$green-lightest: #e3fcec;

$teal: #18d8c6;
$teal-darkest: desaturate(darken($teal, 34%), 8%);
$teal-darker: desaturate(darken($teal, 22%), 0%);
$teal-dark: darken($teal, 9%);
$teal-light: desaturate(lighten($teal, 17%), 10%);
$teal-lighter: lighten($teal, 38%);
$teal-lightest: lighten($teal, 48%);

$blue: #106bf3;
$blue-darkest: desaturate(darken($blue, 40%), 8%);
$blue-darker: desaturate(darken($blue, 22%), 0%);
$blue-dark: darken($blue, 10%);
$blue-light: lighten($blue, 10%);
$blue-lighter: lighten($blue, 34%);
$blue-lightest: lighten($blue, 44%);

$indigo: #3f55d4;
$indigo-darkest: desaturate(darken($indigo, 40%), 8%);
$indigo-darker: desaturate(darken($indigo, 22%), 0%);
$indigo-dark: darken($indigo, 10%);
$indigo-light: lighten($indigo, 10%);
$indigo-lighter: lighten($indigo, 34%);
$indigo-lightest: lighten($indigo, 44%);

$lvn-purple: #6351d9;
$lvn-purple-darkest: desaturate(darken($lvn-purple, 40%), 8%);
$lvn-purple-darker: desaturate(darken($lvn-purple, 24%), 0%);
$lvn-purple-dark: darken($lvn-purple, 10%);
$lvn-purple-light: lighten($lvn-purple, 10%);
$lvn-purple-lighter: lighten($lvn-purple, 34%);
$lvn-purple-lightest: lighten($lvn-purple, 40%);

$purple: #8e3fd4;
$purple-darkest: desaturate(darken($purple, 40%), 8%);
$purple-darker: desaturate(darken($purple, 22%), 0%);
$purple-dark: darken($purple, 10%);
$purple-light: lighten($purple, 10%);
$purple-lighter: lighten($purple, 34%);
$purple-lightest: lighten($purple, 44%);

$pink: #ef407b;
$pink-darkest: desaturate(darken($pink, 40%), 8%);
$pink-darker: desaturate(darken($pink, 22%), 8%);
$pink-dark: desaturate(darken($pink, 10%), 5%);
$pink-light: lighten($pink, 14%);
$pink-lighter: lighten($pink, 32%);
$pink-lightest: lighten($pink, 38%);

$white: #fff;
$gray-100: #fbfcfd; //#f8fafc;
$gray-200: #f5f8fa; //#f1f5f8;
$gray-300: #ebf1f5; //#e7eef3;
$gray-400: #dfe6ec; //#dae1e7;
$gray-500: #cad1d8; //#b8c2cc;
$gray-600: #8795a1;
$gray-700: #606f7b;
$gray-800: #3d4852;
$gray-900: #22292f;
$black: #000;

$colors: (
  red-darkest: $red-darkest,
  red-darker: $red-darker,
  red-dark: $red-dark,
  red: $red,
  red-light: $red-light,
  red-lighter: $red-lighter,
  red-lightest: $red-lightest,
  orange-darkest: $orange-darkest,
  orange-darker: $orange-darker,
  orange-dark: $orange-dark,
  orange: $orange,
  orange-light: $orange-light,
  orange-lighter: $orange-lighter,
  orange-lightest: $orange-lightest,
  yellow-darkest: $yellow-darkest,
  yellow-darker: $yellow-darker,
  yellow-dark: $yellow-dark,
  yellow: $yellow,
  yellow-light: $yellow-light,
  yellow-lighter: $yellow-lighter,
  yellow-lightest: $yellow-lightest,
  green-darkest: $green-darkest,
  green-darker: $green-darker,
  green-dark: $green-dark,
  green: $green,
  green-light: $green-light,
  green-lighter: $green-lighter,
  green-lightest: $green-lightest,
  teal-darkest: $teal-darkest,
  teal-darker: $teal-darker,
  teal-dark: $teal-dark,
  teal: $teal,
  teal-light: $teal-light,
  teal-lighter: $teal-lighter,
  teal-lightest: $teal-lightest,
  blue-darkest: $blue-darkest,
  blue-darker: $blue-darker,
  blue-dark: $blue-dark,
  blue: $blue,
  blue-light: $blue-light,
  blue-lighter: $blue-lighter,
  blue-lightest: $blue-lightest,
  indigo-darkest: $indigo-darkest,
  indigo-darker: $indigo-darker,
  indigo-dark: $indigo-dark,
  indigo: $indigo,
  indigo-light: $indigo-light,
  indigo-lighter: $indigo-lighter,
  indigo-lightest: $indigo-lightest,
  lvn-purple-darkest: $lvn-purple-darkest,
  lvn-purple-darker: $lvn-purple-darker,
  lvn-purple-dark: $lvn-purple-dark,
  lvn-purple: $lvn-purple,
  lvn-purple-light: $lvn-purple-light,
  lvn-purple-lighter: $lvn-purple-lighter,
  lvn-purple-lightest: $lvn-purple-lightest,
  purple-darkest: $purple-darkest,
  purple-darker: $purple-darker,
  purple-dark: $purple-dark,
  purple: $purple,
  purple-light: $purple-light,
  purple-lighter: $purple-lighter,
  purple-lightest: $purple-lightest,
  pink-darkest: $pink-darkest,
  pink-darker: $pink-darker,
  pink-dark: $pink-dark,
  pink: $pink,
  pink-light: $pink-light,
  pink-lighter: $pink-lighter,
  pink-lightest: $pink-lightest,
);

$theme-colors: (
  primary: $lvn-purple,
  white: $white,
  secondary: $gray-400,
  success: $green,
  info: $teal-dark,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  gray: $gray-600,
  lvn-purple: $lvn-purple,
);

// Retrieve color Sass maps
@function color($key: 'blue') {
  @return map-get($colors, $key);
}

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

@function gray($key: '100') {
  @return map-get($grays, $key);
}

$link-color: theme-color('primary');
$link-hover-color: darken($link-color, 15%);
$component-active-bg: theme-color('primary');
$list-group-active-bg: $component-active-bg;

// dropdowns
$dropdown-link-color: $gray-900;
// $dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: $gray-300;
$dropdown-link-active-bg: $gray-400;
$dropdown-link-active-color: $gray-900;
$dropdown-border-color: $gray-300;
$dropdown-item-padding-x: 1rem;

// layout
$main-nav-height: 40px;

// boxes
$box-padding-1: 1.5rem;
$box-padding-2: 1rem;

// tables
$table-accent-bg: hsla(199, 25%, 38%, 0.06);

// charts
$grid-line-color: #eee;
$tick-text-color: #aaa;
$axis-line-color: #aaa;

// topics colors
// background, text color
// if the number of topics changes, update the NUM_COLORS constant in src/util/topics.ts
$topics-colors: (
  ($yellow, $gray-900),
  ($teal, $white),
  ($orange, $white),
  ($green, $white),
  ($pink, $white),
  ($blue, $white),
  ($purple, $white)
);

$font-family-sans-serif: open_sans, BlinkMacSystemFont, -apple-system,
  'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$font-family-headings: 'knockout', $font-family-sans-serif;

$component-active-color: $white;
$component-active-bg: theme-color('primary');

$border-color: $gray-400;
$input-color: $gray-800;
$popover-border-color: $border-color;

$box-shadow-xs: 0 2px 0 rgba($black, 0.015);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.04);
$box-shadow: 0 0.2rem 0.25rem rgba($black, 0.08);
$box-shadow-md: 0 0.5rem 1.5rem rgba($black, 0.12);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$popover-box-shadow: $box-shadow;
$dropdown-box-shadow: $box-shadow;

// buttons
$btn-box-shadow: $box-shadow-xs;
$input-btn-padding-y: 0.25rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-y-lg: 0.3rem;
$input-btn-padding-x-lg: 1.25rem;
$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.4);
$input-btn-focus-box-shadow: none;
$custom-select-line-height: 1; // otherwise text is a bit too low

$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $btn-box-shadow;
$btn-transition: color 0.15s linear, background-color 0.15s linear,
  border-color 0.15s linear, box-shadow 0.15s linear !default;

// input
$input-padding-x: 0.75rem;

// alerts
$alert-color-level: 8; // higher number = darker font

/////////////////////////////
// BOOTSTRAP IMPORTS. (defaults will be overridden by above)
/////////////////////////////

// Core variables and mixins
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/////////////////////////////
// You can use all bootstrap variables below here
/////////////////////////////
$main-padding: 24px;
$nav-padding: 20px;
$nav-logo-right-margin: 10px;
$nav-logo-width: 40px;
$nav-logo-height: $nav-logo-width;
$nav-logo-outer-width: $nav-padding * 2 + $nav-logo-width +
  $nav-logo-right-margin;
$main-nav-height: 62px;
$main-left-nav-width: 90px;
$base-left-margin: $nav-logo-outer-width - $main-left-nav-width;

$font-sizes: (
  // use with text-transform: uppercase
  10: 0.7rem,
  100: 0.85rem,
  200: 0.9rem,
  250: 0.95em,
  300: 1rem,
  // 1.125rem = 18px
  400: 1.125rem,
  // useful for large icons,
  1000: 1.75rem
);

@mixin panel {
  padding: 2rem;
  padding-top: 1.5rem;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 2px 2px hsl(208, 23%, 85%);
}

// popover overrides
$popover-border-color: $gray-300;
$popover-header-bg: $white;

// modal
$modal-overlay-background: rgba(255, 255, 255, 0.7);
$modal-content-background: $white;
