@import '../assets/util';

.choice {
  width: 100%;
  position: relative;
  p:last-of-type {
    margin-bottom: 0;
  }
  input {
    margin-left: -0.25rem;
    top: 50%;
    bottom: 50%;
    margin: auto;
  }
  border: 1px solid $gray-400;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 1rem;

  .content {
    margin-left: 2rem;
  }

  &.active {
    border: 1px solid $black;
  }
}

.highlightBtn {
  padding: 0 !important;
  margin: 0;
}

.legend {
  font-size: 1rem;
}

.indexedStep {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.indexedStep.accomplished {
  background-color: $lvn-purple;
}
