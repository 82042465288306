.scriptContent {
  p:last-of-type {
    margin-bottom: 0;
  }
}

.embed {
  border: none;
  height: 14rem;
}
