// import variables
@import './util';

// import bootstrap
@import './bootstrap_custom';

@import './fonts.scss';

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: $font-family-headings;
}

.card {
  border-top: 1px solid rgba(0, 0, 0, 0.02);
  background: $white;
}

.list-style-none {
  list-style-type: none;
}

// generate bgs and fgs for gray
@each $color, $value in $grays {
  @include bg-variant('.bg-gray-#{$color}', $value);
}
@each $color, $value in $grays {
  @include text-emphasis-variant('.text-gray-#{$color}', $value);
}

.btn {
  border-radius: 2px;
}

.btn-primary {
  font-family: $font-family-headings;
  text-transform: uppercase;
}

.btn-important {
  font-family: $headings-font-family;
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1.75rem;
  height: auto;
  padding: 0 0.6em;
}

.nav-item {
  padding-left: 1rem;
}

.content-width {
  width: 55rem;
}
