@import '../assets/util';

.panel {
  @include panel();
}

@page {
  size: A4;
}

.print {
  margin-left: 1cm;
  width: 170mm;

  section {
    page-break-inside: avoid;
  }
}
